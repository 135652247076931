<template>
  <header>
    <div class="d-flex">
      <img
        :src="`https://secure.gravatar.com/avatar/${md5(_admin.email)}?s=64`"
        alt=""
      />
      <h6 class="me-3 mb-0">
        {{ _admin.full_name }}
        <small class="text-primary">
          {{ _admin.role == "super_admin" ? "Arkadyas Yetkilisi" : "" }}
          {{ _admin.role == "admin" ? "Firma Yetkilisi" : "" }}
          {{ _admin.role == "staff" ? "Firma Personeli" : "" }}
        </small>
      </h6>
    </div>

    <button class="btn btn-danger shadow btn-iconed" @click="Logout">
      Güvenli çıkış <i class="bi bi-box-arrow-right ms-2"></i>
    </button>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import md5 from "crypto-js/md5";

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters({
      _admin: "Auth/_admin",
    }),
  },
  methods: {
    Logout() {
      this.$store.dispatch("Auth/LOGOUT");
      setTimeout(() => {
        this.$swal({
          icon: "success",
          text: "Oturumunuz güvenli bir şekilde kapatıldı",
        });
        this.$router.push({ name: "Login" });
      }, 100);
    },
    md5,
  },
};
</script>
<style lang="scss" scoped>
header {
  background-color: white;
  height: 70px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    display: flex;
    flex-direction: column;

    small {
      font-size: 11px;
      font-weight: 400;
    }
  }

  .d-flex {
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
}
</style>
