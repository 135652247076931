import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* AXIOS */
import axios from "axios";
axios.defaults.baseURL =
  process.env.VUE_APP_BASE || "http://localhost:3000/api/v1";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response.data.type == "unauthorized_access") {
      store.dispatch("Auth/LOGOUT");
      router.push({ name: "Login" });
    }
    // if (error?.response.data.type == "permission_denied") {
    //   router.push({ name: "Home" });
    // }
    return Promise.reject(error);
  }
);

/* SWEETALERT 2 */
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/* BOOTSTRAP */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/* BOOTSTRAP ICONS */
import "bootstrap-icons/font/bootstrap-icons.css";

/* CKEDİTOR */
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";

import Title from "@/utils/MailSoftwareTitle";
import Breadcrumb from "@/components/shared/Breadcrumb";
import CompLoader from "@/components/shared/CompLoader";

import "@/assets/style.css";

createApp(App)
  .use(store)
  .use(router)
  .use(CkeditorPlugin)
  .use(VueSweetalert2, {
    toast: true,
    timer: 5 * 1000,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
  })
  .component("mail-software-title", Title)
  .component("mail-software-breadcrumb", Breadcrumb)
  .component("mail-software-loader", CompLoader)
  .mount("#app");
