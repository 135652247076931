<script>
export default {
  name: "digiindeks-title",
  props: ["title"],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title
          ? this.title + " | Toplu Mail"
          : "Toplu Mail | keyiflerolsun";
      },
    },
  },
  render: () => "",
};
</script>
