<template>
  <transition name="loader" mode="out-in">
    <AppLoader v-if="loader" />
    <div class="app_wrapper" v-else-if="_auth">
      <AppSidebar />

      <div class="content_wrapper container mx-auto">
        <AppHeader />
        <router-view v-slot="{ Component }">
          <transition name="router" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

      <AppTasks />
    </div>
    <router-view v-else v-slot="{ Component }">
      <transition name="router" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </transition>
</template>

<script>
import AppSidebar from "@/components/shared/AppSidebar";
import AppHeader from "@/components/shared/AppHeader";
import AppLoader from "@/components/shared/AppLoader";
import AppTasks from "./components/shared/AppTasks";

import { mapGetters } from "vuex";

export default {
  components: {
    AppSidebar,
    AppHeader,
    AppLoader,
    AppTasks,
  },
  data() {
    return {
      loader: true,
    };
  },
  computed: {
    ...mapGetters({
      _token: "Auth/_token",
      _admin: "Auth/_admin",
      _auth: "Auth/_auth",
    }),
  },
  beforeCreate() {
    this.$store.dispatch(
      "Auth/SET_TOKEN",
      localStorage.getItem("AUTH_TOKEN") || ""
    );

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this._token) {
        return next({ name: "Login" });
      } else if (to.meta.auth && this._token) {
        return this.$store
          .dispatch("Auth/CHECK_AUTH")
          .then(() => {
            if (!this._admin) {
              return next({ name: "Login" });
            } else {
              next();
            }
          })
          .catch((err) => {
            next({ name: "Login" });
            return this.$swal({
              icon: "error",
              text: err.message,
            });
          });
      }
      if (to.name === "Login" && this._token) {
        return next("/");
      }
      next();
    });
  },
  created() {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
body:has(.company_page) {
  background: #f8f8f8;
}
.company_page {
  margin-top: 100px !important;
}
.app_wrapper {
  display: flex;

  .content_wrapper {
    width: 100%;
  }
}

.loader-enter-active,
.loader-leave-active {
  transition: opacity 0.5s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}

.blur-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-to,
.blur-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.blur-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-active,
.blur-leave-active {
  transition: all 450ms ease-in-out;
}

.router-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-to,
.router-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.router-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.router-enter-active,
.router-leave-active {
  transition: all 450ms ease-in-out;
}
</style>
