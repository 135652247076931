<template>
  <div class="breadcrumb">
    <div class="content">
      <div>
        <i class="bi" :class="`bi-` + icon"></i>
      </div>
      <div>
        <h6>{{ title }}</h6>
        <p>{{ description }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MailSoftwareBreadcrumb",
  props: ["title", "description", "icon"],
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #fff0ec;
  padding: 20px 25px;
  border-radius: 12px;
  margin: 30px 0;

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    i {
      font-size: 26px;
      color: #b4b4b4;
    }

    p,
    h6 {
      margin: 0;
    }

    h6 {
      font-weight: 600;
      font-size: 1.1rem;
    }

    p {
      font-weight: 300;
    }
  }
}
</style>
