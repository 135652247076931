import axios from "axios";

export default {
  namespaced: true,
  state: {
    tasks: [],
    local_tasks: [],
    tasks_open: false,
  },
  getters: {
    _tasks(context) {
      return context.tasks;
    },
    _local_tasks(context) {
      return context.local_tasks;
    },
    _tasks_open(context) {
      return context.tasks_open;
    },
  },
  mutations: {
    setTasks(context, payload) {
      context.tasks = payload;
    },
    setLocalTasks(context, payload) {
      context.local_tasks = payload;
    },
    setTasksOpen(context, payload) {
      context.tasks_open = payload;
    },
    setLocalTaskIncrement(context, payload) {
      let task = context.local_tasks.findIndex(
        (task) => task._id == payload.id
      );
      context.local_tasks[task].completed = payload.percent;
    },
    setLocalTaskStatus(context, payload) {
      let task = context.local_tasks.findIndex(
        (task) => task._id == payload.id
      );
      context.local_tasks[task].status = payload.status;
    },
    setLocalTaskText(context, payload) {
      let task = context.local_tasks.findIndex(
        (task) => task._id == payload.id
      );
      context.local_tasks[task].text = payload.text;
    },
  },
  actions: {
    GET_TASKS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/tasks/get`, {
            headers: {
              Authorization: `Bearer ${context.getters._token}`,
            },
          })
          .then((res) => {
            context.commit("setTasks", res.data.tasks);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
