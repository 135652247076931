import { createStore } from "vuex";

import Auth from "./modules/Auth";
import Admins from "./modules/Admins";
import Mails from "./modules/Mails";
import Tasks from "./modules/Tasks";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Admins,
    Mails,
    Tasks,
  },
});
