<template>
  <main id="HomeView">
    <mail-software-title />

    <div class="comingsoon">
      <h5>Yapım Aşamasında</h5>
      <img src="@/assets/svg/comingsoon.svg" alt="" />
      <p class="text-secondary">
        Hoş geldiniz sn.
        <span class="text-danger">{{ _admin.full_name }}</span> Kontrol paneli
        yapım aşamasındadır.
      </p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {},
  computed: {
    ...mapGetters({
      _admin: "Auth/_admin",
    }),
  },
};
</script>

<style lang="scss" scoped>
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  img {
    width: 500px;
  }
}
</style>
