<template>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompLoader",
};
</script>
<style lang="scss" scoped></style>
