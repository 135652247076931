import axios from "axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    UPDATE_ADMIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admins/update`, payload, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
  },
};
