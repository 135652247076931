import axios from "axios";

export default {
  namespaced: true,
  state: {
    mails: [],
  },
  getters: {
    _mails(state) {
      return state.mails;
    },
  },
  mutations: {
    setMails(state, payload) {
      state.mails = payload;
    },
  },
  actions: {
    GET_MAILS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mails`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            context.commit("setMails", res.data.mails);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setMails", []);
            reject(err.response.data);
          });
      });
    },
    SAVE_MAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mails/save`, payload, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    DELETE_MAIL(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mails/delete/${payload}`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    DELETE_ALL_MAILS(context) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mails/delete_all`, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    SEND_MAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mails/send_mail`, payload, {
            headers: {
              Authorization: "Bearer " + context.rootGetters["Auth/_token"],
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
  },
};
