<template>
  <div class="AppLoader">
    <img src="@/assets/loader.png" class="loader" alt="" />
  </div>
</template>
<script>
export default {
  name: "AppLoader",
};
</script>
<style lang="scss" scoped>
.AppLoader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    width: 64px;
  }
}
</style>
